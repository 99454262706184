import { PlanFeatureBillingMethod, PlanFeatureCode } from '~/graphql';

export const PlanFeatureCodeLabel = {
  [PlanFeatureCode.Checkin]: '店舗チェックイン',
  [PlanFeatureCode.Facetoface]: '処方箋ネット受付',
  [PlanFeatureCode.Followup]: '服薬フォローアップ',
  [PlanFeatureCode.MedicineNote]: '電子お薬手帳',
  [PlanFeatureCode.Online]: 'オンライン服薬指導',
  [PlanFeatureCode.TracingReport]: 'トレーシングレポート',
  [PlanFeatureCode.PrintPrescription]: '処方箋自動印刷',
  [PlanFeatureCode.OutpatientQuestionnaire]: 'Web問診',
};

export const PlanFeatureBillingMethodLabel = {
  [PlanFeatureBillingMethod.Flat]: '定額',
  [PlanFeatureBillingMethod.Metered]: '従量',
};
