globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"77396ae86e8d5574101dea3f26017ee0a6e18206"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/operator/assets/production-20241210091741-77396ae86e8d5574101dea3f26017ee0a6e18206";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.sentryDsn,
  enabled: process.env.NODE_ENV !== 'test',
  release: process.env.sentryRelease,
  ignoreErrors: [],
});
